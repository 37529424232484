import $ from 'jquery';
// smooth scroll
$(function () {
  $('a[href^="#"]').on('click', function () {
    var speed = 1000;
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    var position = target.offset().top;
    $('html, body').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });
});

//Window height
$(window).on('load resize', function () {
  var hdr = $('.main-header');
  var hdrHeight = hdr.outerHeight(true);
  var h = $(window).height();
  $('#kv .inner').css('height', h - hdrHeight);
});

//accordion menu
$(function () {
  $('.faq_list dt').on('click', function () {
    $(this).next().slideToggle();
    $(this).toggleClass('open');
  });
});

//nav
$(function () {
  $('.menuTrigger').on('click', function () {
    $(this).toggleClass('open');
    $('.head-nav').toggleClass('open');
  });
  $('header .nav a').on('click', function () {
    $('.head-nav').toggleClass('open');
  });
});
